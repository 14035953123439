import React,{	Component	} from 'react';
import FileSaver,{saveAs} from 'file-saver';
import XLSX from 'xlsx';
import moment from 'moment';
import {	endpoint, endpointSocketScheduled, endpointSocketRoadmap, endpointDownload	} from '../../config';
import Loading from '../../components/Loading';
import MsgError from '../../components/MsgError';
import TracertContext from '../../context/app-context';
import initial_state from './initial_state';

import VehiclesList from './VehiclesList';
import ThingsToDo from './ThingsToDo';
import { getInfo,getStatusCheckBox,getMoreData,getDataToUpdate} from './functions';

import socketIOClient from "socket.io-client";

class RoadMap extends Component {
	static contextType = TracertContext;

	state = {
		date: this.context.fecha,
		...initial_state,
		socketScheduled: null,
		socketRoadmap: null
	}

	handleChangeDate(date){ 
		date!=='' ? this.setState({	date }) : this.setState({error:'Debes ingresar una fecha válida'});
	};

	getVehicles(){
    fetch(`${endpoint}/vehicles`)
      .then(res => res.json())
      .then((result) => {	this.setState({vehicles: result }); }
      ).then(()=>this.getLocations())
	  .catch(err => console.log(err))
  	}

  getLocations(){
  	fetch(`${endpoint}/locations`)
    .then(res => res.json())
    .then((result) => { this.setState({locations: result });}
	).then(()=>this.getTasks("true"))
	.then(()=>this.getTasks("false"))
	.then(()=>this.getZones())
	.catch(err => console.log(err))
  }

  getZones(){
	fetch(`${endpoint}/zones`)
	.then(res => res.json())
	.then( (result) => this.setState({zones: result,loading:false,isLoaded:true}))
	.catch(err => this.setState({error:err}))
}

	getTasks(type){
		let fecha = this.state.date.format("YYYY-MM-DD");
		let token = localStorage.getItem('token');
		this.setState({loading:true});
		fetch(`${endpoint}/tasks/fechas/${type}/${fecha}`,{headers:{
      'Content-Type': 'application/json','authorization': `${token}`
    	}})
      .then(res => res.json())
      .then(response => {
		if(response.error===403){
			this.setState({error:"Solamente disponible desde 08:00 hs"});
    	}else{
			type==='true' ? this.setState({data:getInfo(response)}) : this.setState({data2:getInfo(response)})
		}
		})
	.catch(err => console.log(err))
	}

	handleCheckBox(i,tipo,data,e){
		tipo==='true' ? this.setState({data:getStatusCheckBox(data,i)}) : this.setState({data2:getStatusCheckBox(data,i)});
	}

	reporte(i,data,locations,e){
		let url = `${endpointDownload}/downloads`;	
	    let payload = { tasks: data[i],locations,token:localStorage.getItem('token') };
	    fetch(url, {
	      method: 'POST',
	      body: JSON.stringify(payload),
	      headers:{
	    	'Content-Type': 'application/json'
	    	}
	    }).then(res => res.blob())
	    .then(response => {	
	    	var blob = new Blob([response], {type: "application/pdf"});
			FileSaver.saveAs(blob, `${data[i]._id}_${data[i].tasks[0].date}.pdf`);
	    })
	    .catch(err => {
	        this.setState({error:err.message})
	    })
	}

	s2ab(s) { 
	  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	  var view = new Uint8Array(buf);  //create uint8array as viewer
	  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
	  return buf;    
	}

	reporteExcel(i,data,e){
		let {tasks,_id} = data[i];
		var wb = XLSX.utils.book_new();
	  	let name = `${_id}_${moment().format('YYYY_MM_DD')}`;
	  	wb.Props = {
	    	Title: name,Subject: "Tracert",
	    	Author: "Tracert",CreatedDate: moment().format('dd MM YYYY')
	  	};
	  	wb.SheetNames.push(name);
		  let titles = ["NOMBRE CLIENTE","LOCALIDAD","DIRECCIÓN","NÚM. TEL.",
		  "TIPO TAREA","HORARIO","TÉC. A CARGO","OBSERVACIONES"];
	  	let ws_data = [];
		ws_data.push(titles);
		for(let i=0;i<tasks.length;i++) ws_data.push(getMoreData(tasks[i]));
	  	var ws = XLSX.utils.aoa_to_sheet(ws_data);
	  	wb.Sheets[name] = ws;
	  	var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
	  	saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), `${name}.xlsx`);
	}

	componentDidMount(){
		this.getVehicles();
		/*
		let day = moment().format('YYYY-MM-DD');
		this.context.socket.on("get_data_scheduled_true",(data)=>{
			if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data:getDataToUpdate(data,this.state.data)});
		})
		this.context.socket.on("get_data_scheduled_false",(data) => {
			if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data2:getDataToUpdate(data,this.state.data2)});
		});
		this.context.socket.emit("subscriberUpdateRoadmap",localStorage.getItem('role'));
        this.context.socket.on("subscriberRoadmap",(data)=>{
			if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data:getDataToUpdate(data,this.state.data)});
		});
		*/
		this.setState({socketScheduled:socketIOClient(endpointSocketScheduled)});
		this.setState({socketRoadmap:socketIOClient(endpointSocketRoadmap)});
	} 

	componentDidUpdate(prevProps,prevState) {
		if(prevState.date!==this.state.date){
			this.setState({ loading: true })
			this.getVehicles();
		}
		if(prevState.socketRoadmap!==this.state.socketRoadmap){
			let day = moment().format('YYYY-MM-DD');
			this.state.socketRoadmap.emit("subscriberUpdateRoadmap",localStorage.getItem('role'));
			this.state.socketRoadmap.on("subscriberRoadmap",(data)=>{
				if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data:getDataToUpdate(data,this.state.data)});
			});
		}
		if(prevState.socketScheduled!==this.state.socketScheduled){
			let day = moment().format('YYYY-MM-DD');
			this.state.socketScheduled.on("get_data_scheduled_true",(data)=>{
				if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data:getDataToUpdate(data,this.state.data)});
			})
			this.state.socketScheduled.on("get_data_scheduled_false",(data) => {
				if(this.context.fecha.format('YYYY-MM-DD')===day) this.setState({data2:getDataToUpdate(data,this.state.data2)});
			});
		}
	}

	componentWillUnmount(){
		this.state.socketRoadmap.close();
		this.state.socketScheduled.close();
	}

	onChangeDate(date){
		this.context.setFecha(date);
		this.setState({date})
	}
	enviar(){ window.open("https://plataforma.redgps.com/login");}

	render(){
		const {isLoaded,error,loading} = this.state;
		if (error) {
			return <MsgError message={error}/>;
		} else if(!isLoaded){
			return	<Loading/>;
		}else {
			return(
			<main className="roadmap">
				<div className="roadmap-aside">
					<div className="table-responsive" style={{"width": "100%"}}>
									
					</div>
				</div>
				<div className="roadmap-body">
				{loading ? (<Loading/>) : 
				(
				<React.Fragment>
					<ThingsToDo tipo="true" title={"Tareas con horario"} locations={this.state.locations} data={this.state.data} 
					enviar={this.enviar} handleCheckBox={this.handleCheckBox.bind(this)} zones={this.state.zones}
					reporte={this.reporte.bind(this)} reporteExcel={this.reporteExcel.bind(this)} 
					/>
					<ThingsToDo tipo="false" title={"Tareas sin horario"} locations={this.state.locations} data={this.state.data2} 
					enviar={this.enviar} handleCheckBox={this.handleCheckBox.bind(this)} zones={this.state.zones}
					reporte={this.reporte.bind(this)} reporteExcel={this.reporteExcel.bind(this)} 
					/>
					<VehiclesList vehicles={this.state.vehicles} data={this.state.data} data2={this.state.data2}/>
				</React.Fragment>
				)}
				</div>
				</main>
			);
		}
	}
}

export default RoadMap;