/*
const protocol = 'http';
const domain = 'localhost';
export const toHome = '/';
export const endpointHome = `${protocol}://${domain}:3000`;
export const endpoint = `${protocol}://${domain}:4003`;
export const endpoint2 = `${protocol}://${domain}:3000`;
export const endpoint3 = `${protocol}://${domain}`;
export const endpointSocketSound = `${protocol}://${domain}:4010`;
export const endpointNotifications = `${protocol}://${domain}:4011`;
export const endpointSocketMonitor = `${protocol}://${domain}:4012`
export const endpointSocketRoadmap = `${protocol}://${domain}:4013`;
export const endpointSocketScheduled = `${protocol}://${domain}:4014`;
export const endpointSocketData = `${protocol}://${domain}:4015`;
export const endpointDownload = `${protocol}://${domain}:4016`;
export const endpointSound = `${protocol}://${domain}:4017`;
export const endpointImage = `${protocol}://${domain}:4018`;
export const endpointSetKeys = `${protocol}://${domain}:4020`;
*/
const protocol = 'https';
const domain = 'prolux-tracert.com';
export const toHome = '/';
export const endpointHome = `${protocol}://user.${domain}`;
export const endpoint = `${protocol}://api.${domain}`;
export const endpoint2 = `${protocol}://user.${domain}`;
export const endpoint3 = `${protocol}://user.${domain}`;
export const endpointSocketSound = `${protocol}://sounds.${domain}`;
export const endpointNotifications = `${protocol}://notifications.${domain}`;
export const endpointSocketMonitor = `${protocol}://monitor.${domain}`;
export const endpointSocketRoadmap = `${protocol}://roadmap.${domain}`;
export const endpointSocketScheduled = `${protocol}://scheduled.${domain}`;
export const endpointSocketData = `${protocol}://data.${domain}`;
export const endpointDownload = `${protocol}://downloads.${domain}`;
export const endpointSound = `${protocol}://upsounds.${domain}`;
export const endpointImage = `${protocol}://images.${domain}`;
export const endpointSetKeys = `${protocol}://keys.${domain}`;
