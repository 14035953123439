import React from 'react';
import MsgError from '../components/MsgError';

const NoMatch = ({location}) => (
  <React.Fragment>
    <MsgError message={"No se encuentra la ruta disponible"}/>
  </React.Fragment>
);

export default NoMatch;
