import React,{	Component	} from 'react';
import moment from 'moment';
import {	endpoint, endpoint3, toHome, endpointSocketScheduled,endpointSocketData	} from '../config';
import Loading from '../components/Loading';
import MsgError from '../components/MsgError';
import Aside from '../components/Aside';
//modal imports
import Modal from '../components/Modal';
import ModalCancel from '../components/modals/ModalCancel';
import ModalReminder from '../components/modals/ModalReminder';
//context import
import TracertContext from '../context/app-context';
//task elements imports
import initial_state from './task/initial_state';
import Kit from './task/Kit';
import TaskReport from './task/TaskReport';
import ActionsUser from './task/ActionsUser';
import ObservationsReport from './task/ObservationsReport';
import CanceledReport from './task/CanceledReport'; 

import socketIOClient from "socket.io-client";

class Task extends Component {
	static contextType = TracertContext;
	state = { ...initial_state,socketData:null,socketScheduled:null,modalReminder:false }
	//modal methods
	showModal = () => this.setState({ show: true });
	hideModal = () => this.setState({ show: false });
	showModal2 = () => this.setState({ show2: true });
	hideModal2 = () => 	this.setState({ show2: false });
	showModal3 = () => this.setState({ show3: true });
	hideModal3 = () => this.setState({ show3: false });
	showModalReminder = () => this.setState({ modalReminder : true });
	hideModalReminder = () => this.setState({ modalReminder: false });
	//kit methods
	handleChangeKitSelected(kitSelected){ this.setState({	kitSelected	})}
	handleChangeKitPower(kitPower){ this.setState({ kitPower })}

	borrar = () => {
		this.setState({ show: false, buttonEnable: false });
		const token = localStorage.getItem('token');
		fetch(`${endpoint}/tasks`, { method: 'DELETE',
			headers: {'Content-Type': 'application/json','authorization':`${token}`},
			body: JSON.stringify({id: this.state.data._id})
		})
		.then(res => res.json())
		.then(res => {
			if (res.success===true){
				const day = moment().format("YYYY-MM-DD");
				if(this.state.data.date===day){
					this.state.socketData.emit("initial_data",day);
					this.state.socketScheduled.emit("initial_data_scheduled",this.state.data.scheduled);
				}
				this.props.history.push(toHome);
			}
		})
		.catch(err => console.log(err))
	}

	handleChangeObservations(e){ this.setState({observations:e.target.value}) }

	cancelar = () => {
		if(this.state.observations===""){
			this.setState({errorModalCancel:true})
		}else{
			this.setState({errorModalCancel:null,show2:false,observations:""});
			let token = localStorage.getItem('token');
			let user = localStorage.getItem('email');
			fetch(`${endpoint}/tasks/canceled`, { method: 'PUT',
				headers: {'Content-Type': 'application/json','authorization':`${token}`},
				body: JSON.stringify({
					id: this.props.match.params.id,
					user: user, observations: this.state.observations
				})
			})
			.then(res => res.json())
			.then(res => {
				if(res.success===true){
					const day = moment().format("YYYY-MM-DD");
					if(this.state.data.date===day){
						this.state.socketData.emit("initial_data",day);
						this.state.socketScheduled.emit("initial_data_scheduled",this.state.data.scheduled);
					}
					this.props.history.push(toHome);
				}
			})
		}
	}

	completar(){ this.props.history.push(`/service/${this.props.match.params.id}`);}
	//images methods
	seeFrontImage(){ window.open(`${endpoint3}/images/subscribers/${this.state.data.images.front}`,'_blank');}
	seeBackImage(){ window.open(`${endpoint3}/images/${this.state.data.date}/${this.state.data.images.back}`,'_blank');}
	uploadImageBack(){ this.props.history.push(`/uploadback/${this.props.match.params.id}`);}
 
	start(){
		let token = localStorage.getItem('token');
		let user = localStorage.getItem('email');
		fetch(`${endpoint}/tasks/started`, { method: 'PUT',
			headers: {'Content-Type': 'application/json','authorization':`${token}`},
			body: JSON.stringify({ id: this.props.match.params.id, user: user })
		})
		.then(res => res.json())
		.then(res => { if(res.success===true){ this.props.history.push(toHome);}
		})
	}

	terminar(){
		const user = localStorage.getItem('email');
		const token = localStorage.getItem('token');
		const kit = { name: this.state.kitSelected, power: this.state.kitPower };
		fetch(`${endpoint}/tasks`, { method: 'PUT',
		    headers: {'Content-Type': 'application/json','authorization':`${token}`},
		    body: JSON.stringify({ id: this.props.match.params.id,user,kit })
		  })
		  .then(res => res.json())
		  .then(res => {
				if(res.success){
					const day = moment().format("YYYY-MM-DD");
					if(this.state.data.date===day){
						this.state.socketData.emit("initial_data",day);
						this.state.socketScheduled.emit("initial_data_scheduled",this.state.data.scheduled);
					}
					//this.props.history.push(toHome);
					this.setState({isLoaded:false});
					this.showModalReminder();
					this.getTask();
				}
		  })
	  }

	getKit(){
		fetch(`${endpoint}/kit`)
		.then(res => res.json())
		.then((result) => {
			let ok = false;
			for(let i=0;i<result.length;i++){
				if(result[i].name==="NO CORRESPONDE"){
					ok=true;
					break;
				}
			}
			if(ok){
				this.setState({ isLoaded: true, kit: result })
			}else{
				let kits = result.concat({_id:"-1",name:"NO CORRESPONDE"});
				this.setState({ isLoaded: true, kit: kits })
			}
		})
		.catch(err => console.log(err))
	}

	getTask(){
		fetch(`${endpoint}/tasks/${this.props.match.params.id}`)
	    .then(res => res.json())
	    .then(
	        (result) => {
			this.context.setFechaSubida(result.date);
	        this.setState({ data: result });
	        }
		)
		.then(()=>{
			if(this.state.data.kit){
				this.setState({isLoaded: true,kitSelected:this.state.data.kit.name,kitPower:this.state.data.kit.power});
			}else{
				this.getKit();
			}
		  })
		.catch(err => console.log(err))
	}

	modificar(){ this.props.history.push(`/edittask/${this.props.match.params.id}`); }

	savePower(){
		const user = localStorage.getItem('email');
		const token = localStorage.getItem('token');
		const kit = { name: this.state.kitSelected, power: this.state.kitPower };
		fetch(`${endpoint}/tasks/power`, { method: 'PUT',
		    headers: {'Content-Type': 'application/json','authorization':`${token}`},
		    body: JSON.stringify({ id: this.props.match.params.id,user,kit })
		})
		.then(res => res.json())
		.then(res => {
			if(res.success){
				const day = moment().format("YYYY-MM-DD");
				if(this.state.data.date===day){
					this.state.socketData.emit("initial_data",day);
					this.state.socketScheduled.emit("initial_data_scheduled",this.state.data.scheduled);
				}
				this.props.history.push(toHome);
			}
		})
	}

	endWithoutToComplet = () => {
		if(this.state.observations===""){
			this.setState({errorModalCancel:true})
		}else{
			this.setState({errorModalCancel:null,show3:false,observations:""});
			let token = localStorage.getItem('token');
			let user = localStorage.getItem('email');
			fetch(`${endpoint}/tasks/withouttocomplet`, { method: 'PUT',
				headers: {'Content-Type': 'application/json','authorization':`${token}`},
				body: JSON.stringify({ id: this.props.match.params.id,
					user: user, observations: this.state.observations
				})
			})
			.then(res => res.json())
			.then(res => {
				if(res.success===true){
					const day = moment().format("YYYY-MM-DD");
					if(this.state.data.date===day){
						this.state.socketData.emit("initial_data",day);
						this.state.socketScheduled.emit("initial_data_scheduled",this.state.data.scheduled);
					}
					this.setState({isLoaded:false});
					this.showModalReminder();
					this.getTask();
					//this.props.history.push(toHome);
				}
			})
		}
	}

	componentDidMount() { 
		this.getTask();
		this.setState({
			socketData:socketIOClient(endpointSocketData),
			socketScheduled:socketIOClient(endpointSocketScheduled)
		}) 
	}

	componentWillUnmount(){
		this.state.socketData.close();
		this.state.socketScheduled.close();
	}

	render() {
	    const { error, isLoaded, data } = this.state;
	    if (error) return ( <MsgError error={error}/> ); 
		if (!isLoaded)  return <Loading/> ;
	    return (
		<main className="newtask">
			<div className="newtask-aside">
            	<Aside/>
            </div> 
			<div className="newtask-body">
		        <div className="card">
					<div className="card-header">
						<h2>Información general</h2>
					</div>
					<Modal title={"Eliminar tarea"} description={"¿En realidad desea borrar la tarea?"} show={this.state.show} handleClose={this.hideModal} handleAccept={this.borrar}/>
					<ModalCancel title={"Cancelar tarea"} description={"Agregar observación"} show={this.state.show2} handleClose={this.hideModal2} handleAccept={this.cancelar} observations={this.state.observations} handleChangeObservations={this.handleChangeObservations.bind(this)} info={"Debes ingresar una observación"} error={this.state.errorModalCancel}/>
					<ModalCancel title={"Terminar sin completar"} description={"Agregar observación"} show={this.state.show3} handleClose={this.hideModal3} handleAccept={this.endWithoutToComplet} observations={this.state.observations} handleChangeObservations={this.handleChangeObservations.bind(this)} info={"Debes ingresar una observación"} error={this.state.errorModalCancel}/>
					<ModalReminder title={"Recordatorio"} description={"Debes subir una imagen de la conexión realizada"} show={this.state.modalReminder} handleClose={this.hideModalReminder} handleAccept={this.hideModalReminder}/>
					<TaskReport
						type={data.type} creator={data.creator} created_at={data.created_at}
						contacts={data.contacts} location={data.location}
						time={data.time} technicians={data.technicians}
						observations={data.observations}
						completed={data.completed} completed_by={data.completed_by}
						started_at={data.started_at} started_by={data.started_by}
						canceled_at={data.canceled_at} canceled_by={data.canceled_by}
						end_without_to_complet_at={data.end_without_to_complet_at}
						end_without_to_complet_by={data.end_without_to_complet_by}
					/>
					<Kit
						completed={data.completed} canceled_at={data.canceled_at} kits={this.state.kit}
						kit={data.kit} kitSelected={this.state.kitSelected} kitPower={this.state.kitPower}
						handleChangeKitSelected={this.handleChangeKitSelected.bind(this)}
						handleChangeKitPower={this.handleChangeKitPower.bind(this)}
						savePower={this.savePower.bind(this)}
						end_without_to_complet_at={data.end_without_to_complet_at}
					/>
					<ActionsUser
						completed={data.completed} completed_by={data.completed_by} completed_at={data.completed_at}
						front={data.images.front} back={data.images.back}
						seeFrontImage={this.seeFrontImage.bind(this)}
						seeBackImage={this.seeBackImage.bind(this)}
						uploadImageBack={this.uploadImageBack.bind(this)}
						started_at={data.started_at} canceled_at={data.canceled_at}
						start={this.start.bind(this)}
						completar={this.completar.bind(this)}
						terminar={this.terminar.bind(this)}
						showModal2={this.showModal2.bind(this)}
						showModal3={this.showModal3.bind(this)}
						endWithoutToComplet={this.endWithoutToComplet.bind(this)}
						end_without_to_complet_at={data.end_without_to_complet_at}
					/>
		        </div>
				<ObservationsReport 
					service={this.state.data.service}
					end_without_to_complet_info={data.end_without_to_complet_info}
				/>	
				<CanceledReport canceled_at={this.state.data.canceled_at} canceled_info={this.state.data.canceled_info}/>
			</div>
		</main>
	    );
	}
}

export default Task;