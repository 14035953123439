import React from 'react';


export default function ObservationsReport(props) {
    return(
        <div className="card">
			<div className="card-header">
				<h2>Observaciones</h2>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					<table className="table" id="tablon_service">
						<tbody>
				    		<tr>
								<td>
									{(props.service&&props.service.observations) ? (<React.Fragment>{props.service.observations.toUpperCase()}</React.Fragment>):(null)}
									{props.end_without_to_complet_info ? (<React.Fragment>{props.end_without_to_complet_info.toUpperCase()}</React.Fragment>):(null)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="card-footer">
			</div>
		</div>
    );  
}