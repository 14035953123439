import React from 'react';

export default function ActionsUser(props) {
    return(
        <div className="card-footer">
			{((props.completed)||(props.end_without_to_complet_at)) ? (
			<React.Fragment>
				{props.front ? (<button className="btn btn-secondary m-1" onClick={()=>props.seeFrontImage()}>Ver imagen de la casa</button>):null}
				{props.back ? (<button className="btn btn-secondary m-1" onClick={()=>props.seeBackImage()}>Ver imagen de la conexión</button>):null}
				<button className="btn btn-dark m-1" onClick={()=>props.uploadImageBack()}>Subir imagen de la conexión</button>
			</React.Fragment>
			):(
			<React.Fragment>
				{props.front ? (<button className="btn btn-secondary m-1" onClick={()=>props.seeFrontImage()}>Ver imagen de la casa</button>):null}
				{props.back ? (<button className="btn btn-secondary m-1" onClick={()=>props.seeBackImage()}>Ver imagen de la conexión</button>):null}
				{((props.started_at)||(props.canceled_at)) ? (null):(<button className="btn btn-primary" onClick={()=>props.start()}>Iniciar tarea</button>)}
				{(props.canceled_at) ? (
					null
				):(
			    <React.Fragment>
					<button className="btn btn-success m-1" onClick={()=>props.showModal3()}>Terminar sin completar</button>
			        <button className="btn btn-info m-1" onClick={()=>props.completar()}>Agregar observaciones</button>
				    <button className="btn btn-success m-1" onClick={()=>props.terminar()}>Completar</button>
				    <button className="btn btn-dark m-1" onClick={props.showModal2}>Cancelar</button>
			    </React.Fragment>
			    )}
			</React.Fragment>
			)}
		</div>
    );
}