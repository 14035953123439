import React from 'react';
import List from './List';
 
export default function ThingsToDo(props){
    return(
        <div className="card mb-4">
			<div className="card-header d-flex justify-content-between">
				<h5 style={{"marginRight": "5px"}}>{props.title}</h5>
			</div>
			<div className="card-body">
				<ul className="list-group">
				{props.data.map( (v,i) => {
				return (
					<li key={v._id} className="list-group-item">
						<label className="checkbox">
							<h5 className="checkboxtitle">{v._id} : {v.tasks.length}</h5>
							<input className="inputcheckbox" type="checkbox" name="huoa!!"  checked={props.data[i].isChecked} onChange={(e)=>props.handleCheckBox(i,props.tipo,props.data,e)}/>
							<button type="button" style={{"marginBottom": "5px","marginLeft":"5px"}} className="btn btn-outline-primary" onClick={(e)=>props.handleCheckBox(i,props.tipo,props.data,e)}>Ver más</button>
							<button className="btn btn-outline-primary" style={{"marginBottom": "5px","marginLeft":"5px"}} onClick={(e)=>props.reporteExcel(i,props.data,e)}>Generar excel</button>
							<button className="btn btn-outline-primary" style={{"marginBottom": "5px","marginLeft":"5px"}} onClick={(e)=>props.reporte(i,props.data,props.locations,e)}>Generar pdf</button>
						</label>
						<ul className="list-group mt-2">
							{v.technicians.map((t,i) => {
						    	return <li key={i} className="list-group-item bg-dark text-light">{t.name} : {t.cant}</li>
							})}
						</ul>
						<List zones={props.zones} tasks={v.tasks} visible={props.data[i].isChecked} locations={props.locations}/>
					</li>
				)})}
			    </ul>
			</div>
		</div>
    );
}
