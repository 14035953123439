import React,{	Component	} from 'react';
import {	endpoint } from '../config';

import TextArea from '../components/TextArea';
import Loading from '../components/Loading';
import MsgError from '../components/MsgError';

import ModalReminder from '../components/modals/ModalReminder';

import Aside from '../components/Aside';

class Service extends Component {

	state = {
    error: null,
		errorService: null,
    isLoaded: false,
    ssid: '',
    version: '',
    kit: '',
    ccq: '',
    signal: '',
    observations: '',
		db: '',
		mdrop: '',
		isChecked: false,
		buttonEnable: true,
		modalReminder:false 
	}

	showModalReminder = () => this.setState({ modalReminder : true });
	hideModalReminder = () => this.setState({ modalReminder: false });

	pushTask = () => {
		this.hideModalReminder();
		this.props.history.push(`/roadmap/${this.props.match.params.id}`);
	};

	getKits(){
    fetch(`${endpoint}/kit`)
      .then(res => res.json())
      .then(
        (result) => {
           this.setState({kits: result, kit: result[0].name,isLoaded:true});
        }
      ).catch(err => {
				this.setState({error: err})
			})
  }

	componentDidMount(){
		this.getKits();
	}

  handleChangeSSID(ssid){ this.setState({ ssid  })  };
  handleChangeVersion(version){ this.setState({ version  }) };
  handleChangeCCQ(ccq){ this.setState({ ccq }) };
  handleChangeSignal(signal){ this.setState({ signal }) };
  handleChangeObservations(observations){ this.setState({ observations })  };
	handleChangeKit(kit){	this.setState({	kit	})};
	handleChangeDb(db){ this.setState({ db })};
	handleChangeMDrop(mdrop){this.setState({mdrop})}


  completar(){
		const {observations} = this.state;
		const user = localStorage.getItem('email');
		const token = localStorage.getItem('token');
		if(observations!==""){
			this.setState({buttonEnable:false});
			fetch(`${endpoint}/tasks/service`, {
		    method: 'PUT',
		    headers: {'Content-Type': 'application/json','authorization':`${token}`},
		    body: JSON.stringify({
		      id: this.props.match.params.id,
		      observations,user
		    })
		  })
		  .then(res => res.json())
		  .then(res => {
				if(res.success){ this.showModalReminder(); }
		  })
		}else {
			this.setState({errorService: 'Debes completar todos los campos'});
		}
  }

	render(){
		const {isLoaded,error,buttonEnable} = this.state;
		if(error){
			return <MsgError error={error.message}/>
		}else {
			if(!isLoaded){
				return <Loading/>
			}
			return(
					<main className="newtask">
							<ModalReminder title={"Recordatorio"} description={"Debes subir una imagen de la conexión realizada"} show={this.state.modalReminder} handleClose={this.pushTask} handleAccept={this.pushTask}/>
							<div className="newtask-aside">
								<Aside/>
							</div>
							<div className="newtask-body">
  								<div className="card">
  									<div className="card-header">
  										<h2>OBSERVACIONES</h2>
  									</div>
										
  									<div className="card-body">

											


                      <TextArea label={""} value={this.state.observations} handleChange={this.handleChangeObservations.bind(this)}/>
											{(this.state.errorService) ? (<div className="alert alert-danger">{this.state.errorService}</div>) : null }
										</div>
  									<div className="card-footer">
											{buttonEnable ? (
												<button className="btn btn-primary" onClick={()=>this.completar()}>Completar</button>
											):(
												<button className="btn btn-primary" disabled>Completar</button>
											)}
  									</div>
  								</div>
							</div>

					</main>
			);
		}
	}
}

export default Service;

/*
	
	<div className="input-group">
												<div className="input-group-text">
													<input  type="checkbox" onChange={this.handleCheckBox.bind(this)} value={this.state.isChecked}/>
												</div>
												<label className="form-control" aria-label="Text input with checkbox">NO CORRESPONDE</label>
										</div>

	<div className="form-group col-md-6">
												<label>Equipo</label>
												<select className="form-control" value={this.state.kit} onChange={(e)=>this.handleChangeKit(e.target.value)}>
													{this.state.kits.map((v) => {
														return (<option value={v.name} key={v._id}>{v.name}</option>)
													})}
												</select>
											</div>
										</div>
											<div className="form-row mb-2">
												<Input label={"SSID"} value={this.state.ssid} handleChange={this.handleChangeSSID.bind(this)}/>
												<Input label={"Versión"} value={this.state.version} handleChange={this.handleChangeVersion.bind(this)}/>
											</div>

											<div className="form-row mb-2">
												<Input label={"Metros drop"} value={this.state.mdrop} handleChange={this.handleChangeMDrop.bind(this)}/>
												<Input label={"Db"} value={this.state.db} handleChange={this.handleChangeDb.bind(this)}/>
											</div>

											<div className="form-row mb-2">
												<Input label={"CCQ"} value={this.state.ccq} handleChange={this.handleChangeCCQ.bind(this)}/>
												<Input label={"Señal"} value={this.state.signal} handleChange={this.handleChangeSignal.bind(this)}/>
											</div>

*/