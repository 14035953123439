import React,{Component} from 'react';
import { BrowserRouter,Route, Switch } from 'react-router-dom';
import AppContext, { initial_state } from './context/app-context';
import SignIn from './pages/SignIn';
import RoadMap from './pages/roadmap/RoadMap';
import Task from './pages/Task';
import Service from './pages/Service';
import ScrollToTop from './components/ScrollToTop';
import NoMatch from './pages/NoMatch';
import Header from './components/Header';
import Footer from './components/Footer';
import BackUpload from './pages/BackUpload';

class Root extends Component{
	state = {
		...initial_state,
		setAuth: (auth) => this.setState({auth}),
		setSubscriber: (subscriber) => this.setState({subscriber}),
		setSubscriberName: (subscriberName) => this.setState({subscriberName}),
		setFechaSubida: (fechaSubida) => this.setState({fechaSubida}),
		setRole: (role) => this.setState({role}),
		setFecha: (fecha) => this.setState({fecha})
	}

	render(){
		return(
		<AppContext.Provider value={this.state}>
			<BrowserRouter>
				<ScrollToTop>
				{this.state.auth ? (
				<React.Fragment>
					<Header/>
					<Switch>
						<Route exact path="/" render={()=>(
							this.state.auth ? (<RoadMap/>):(<SignIn/>)
						)}/>
						<Route path="/roadmap/:id" component={Task}/>
						<Route path="/service/:id" component={Service}/>
						<Route path="/uploadback/:id" component={BackUpload}/>
						<Route component={NoMatch} />
					</Switch>
				</React.Fragment>
				):(
				<React.Fragment>
					<Switch>
						<Route exact path="/" render={()=>(
							this.state.auth ? (<RoadMap/>):(<SignIn/>)
						)}/>
						<Route component={NoMatch} />
					</Switch>
				</React.Fragment>
				)}
				</ScrollToTop>
				<Footer/>
			</BrowserRouter>
		</AppContext.Provider>
		);
	}
}

export default Root;
