import React from 'react';
import moment from 'moment';

export let initial_state = {
	auth: localStorage.getItem('role')==='user',
	role: '',
	fecha: moment(),
	subscriber: '',
	subscriberName: '',
	fechaSubida: ''
}

export default React.createContext({
	auth: false
});