export default {
    vehicles : [],
	locations: [],
	zones: [],
	isLoaded: false,
	error: null,
	focused: null,
    data: [],
    data2: [],
	isChecked: false,
	loading: true
}