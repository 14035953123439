//const PUBLIC_VAPID_KEY='BDrOysnvNVVxW9LhzAz1TtPvf3MfcPNT_xFsuSEwJ1d4J9-iPqcJJfVS3Y_AJMsvJIDUWlh3K1iZSX9gx1tigLc';
//const ENDPOINT_NOTIFICATIONS='https://tracert-api.tk/subscribe';
//const {endpointSocket} = require("./config");
//const ENDPOINT_NOTIFICATIONS=`${endpointSocket}/subscribe`;

async function send(){
    //console.log('Registering service worker...');
    await navigator.serviceWorker.register('./worker.js',{
        scope: '/'
    })
    //console.log('Service worker registered')
}

function registerSW(){
    if('serviceWorker' in navigator){
        send().catch(err=>console.log(err))
    }
}

export {registerSW};